
/*div {
  background: #191B1F;
}*/


/*
@media (max-width: 992px) {
  body{
    zoom: 200%;
  }
  Form.Select{
    zoom: 400%
  }
}*/
/* styles à appliquer pour les écrans dont la largeur est inférieure à 768px */

/*style={{zoom:"200%"}}*/



.custom-button {
  background-color: #ffffff; /* couleur de fond blanc */
  color: #007bff; /* couleur de texte bleu */
  border-color: #007bff; /* couleur de bordure bleue */
  border-width: 2px; /* épaisseur de la bordure */
}
.custom-button:focus {
  box-shadow: none; /* enlever l'ombre lorsqu'il est focus */
}
.custom-button:hover {
  background-color: red; /* couleur de fond bleue lorsqu'on survole le bouton */
  color: #ffffff; /* couleur de texte blanc lorsqu'on survole le bouton */
}
.custom-button:active, .custom-button.active {
  background-color: #007bff; /* couleur de fond bleue lorsqu'on appuie sur le bouton */
  color: #ffffff; /* couleur de texte blanc lorsqu'on appuie sur le bouton */
}



/*.custom-popup-style {
  border: 1px solid;
  border-radius: 10px;
}*/
